import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";

import { cn } from "@/lib/utils";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return <RadioGroupPrimitive.Root className={cn("grid gap-2", className)} {...props} ref={ref} />;
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    size?: "small" | "medium" | "large";
  } & {
    color?: "default" | "success" | "danger" | "info";
  }
>(({ className, size = "small", color = "default", ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "aspect-square rounded-full border-2 border-platinum-bg text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 hover:border-platinum-stroke hover:bg-platinum-bg hover:ring-brand-500 data-[state=checked]:border-brand-500 relative",
        {
          "h-4 w-4": size === "small",
          "h-6 w-6": size === "medium",
          "h-8 w-8": size === "large"
        },
        {
          "hover:ring-danger-500 data-[state=checked]:border-danger-500": color === "danger"
        },
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Circle
          className={cn(
            "h-2.5 w-2.5 fill-brand-500 text-brand-500 ",
            {
              "h-2.5 w-2.5": size === "small",
              "h-5 w-5 m-auto": size === "medium",
              "h-7.5 w-7.5": size === "large"
            },
            {
              "fill-danger-500 text-danger-500": color === "danger"
            }
          )}
        />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
