import React from "react";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Text } from "@/components/ui/text";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import { ArrowDown2 } from "iconsax-react";
import { cn } from "@/lib/utils";

export interface IBpEventQuestion {
  question_id: string;
  question_version_id: string;
  is_required: boolean;
  question: string;
  type: string; //"single-select"|'text'|'link'|'multi-select';
  choices: { option_id: any; option_text: any }[];
  long_description?: string;
  is_others?: boolean;
  isOpen?: boolean;
}

interface QuestionItemProps {
  question: any;
  queResponses: Record<string, any>;
  hasError: boolean;
  handleTextChange: (text: string, qId: string) => any;
  handleOptionSelection: (selected: any, qId: any, checked?: boolean) => any;
  handleLongDescriptionChange: (text: string, question: IBpEventQuestion) => any;
  index: number;
}

export enum QUESTION_TYPES {
  SINGLE_SELECT = "single-select",
  MULTI_SELECT = "multi-select",
  TEXT = "text",
  LINK = "link"
}

const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  queResponses,
  hasError,
  handleTextChange,
  handleOptionSelection,
  handleLongDescriptionChange,
  index
}) => {
  const isNotAnswered = question.is_required && hasError;

  const handleCheckboxChange = (optionId: string, qId: string) => {
    const currentSelections = queResponses[qId] || [];
    const updatedSelections = currentSelections.includes(optionId)
      ? currentSelections.filter((id: string) => id !== optionId)
      : [...currentSelections, optionId];
    handleOptionSelection(updatedSelections, qId);
  };

  return (
    <div className="mt-xl">
      <div className="m-s">
        <div>
          <label className="text-base font-normal">
            {index + 1}. {question.question}
            {question.is_required ? <span className="text-red-600">*</span> : ""}
            {question.isOpen}
          </label>
        </div>
        <div>
          <div>
            {[QUESTION_TYPES.TEXT, QUESTION_TYPES.LINK].includes(
              question.type as QUESTION_TYPES
            ) && (
              <div className={"bg-container py-s rounded-sm"}>
                <Input
                  // value={queResponses[question.question_id]}
                  value={queResponses[question.response_text]}
                  placeholder="Enter here..."
                  onChange={(e) => handleTextChange(e.target.value, question)}
                  className={isNotAnswered ? "border-red-500" : ""}
                  variant={"small"}
                />
              </div>
            )}
            {question.choices?.length > 0 && (
              <>
                {question.type === QUESTION_TYPES.SINGLE_SELECT && (
                  <RadioGroup
                    value={queResponses[question.question_id]}
                    onValueChange={(value) => handleOptionSelection(value, question)}
                    className={` ${isNotAnswered ? "border-red-500" : ""} flex gap-m flex-wrap`}
                  >
                    {question.choices.map((opt: any, index: number) => (
                      <div className={"bg-container mt-m rounded-sm"} key={index}>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            key={opt.option_id}
                            value={opt}
                            size="small"
                          ></RadioGroupItem>
                          <Text variant={"body_one"}>{opt.option_text}</Text>
                        </div>
                      </div>
                    ))}
                  </RadioGroup>
                )}
                {question.type === QUESTION_TYPES.MULTI_SELECT && (
                  <div className="flex gap-m flex-wrap mt-m">
                    {question.choices.map((opt: any, index: number) => (
                      <div className={"bg-container  rounded-sm"} key={index}>
                        <label key={opt.option_id} className="flex items-center space-x-2">
                          <Checkbox
                            size="xsmall"
                            name={question.question_id}
                            value={opt.option_id}
                            checked={queResponses[question.question_id]?.includes(opt.option_id)}
                            onCheckedChange={(checked: boolean) =>
                              handleOptionSelection(opt, question, checked)
                            }
                            className={`!w-4 !h-4 rounded-4 ${isNotAnswered ? "border-red-500" : ""}`}
                          />
                          <Text variant={"body_one"}>{opt.option_text}</Text>
                        </label>
                      </div>
                    ))}
                  </div>
                )}
                {question.is_others && (
                  <div className={"bg-container py-m rounded-sm my-s"}>
                    <Input
                      value={question.long_description || ""}
                      placeholder="Enter description..."
                      onChange={(e) => handleLongDescriptionChange(e.target.value, question)}
                      className={
                        isNotAnswered && question.is_required && !question.long_description
                          ? "border-red-500"
                          : ""
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionItem;
