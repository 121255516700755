import React from "react";
import Box from "./box";

interface Sidebar {
  isOpen: boolean;
  onClose: (args?: any) => void;
  children: React.ReactNode;
  className?: string;
}

const SideDrawer = ({ isOpen, onClose, children, className }: Sidebar) => {
  return (
    <div>
      <Box
        className={`fixed top-0 right-0 h-full z-[100] backdrop-blur-10 overflow-y-auto w-1/2 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out , ${className}`}
      >
        {children}
      </Box>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={() => onClose(false)}
        ></div>
      )}
    </div>
  );
};

export default SideDrawer;
