import { create } from "zustand";

export interface EventStore {
  questions: any;
  setQuestions: (args: any) => void;
  tickets: any;
  setTickets: (args: any) => void;
  gradientColors: any;
  setGradientColors: (args: any) => void;
  previewMode: boolean;
  setPreviewMode: (previewMode: boolean) => void;
  eventData: any;
  setEventData: (args: any) => void;
  userAccess: string;
  setUserAccess: (args: any) => void;
}

export const useEventStore = create<EventStore>((set) => ({
  questions: [],
  setQuestions: (questions: any) => set({ questions }),
  tickets: [],
  setTickets: (tickets: any) => set({ tickets }),
  gradientColors: {},
  setGradientColors: (gradientColors: any) => set({ gradientColors }),
  previewMode: false,
  setPreviewMode: (previewMode: boolean) => set({ previewMode }),
  eventData: {},
  setEventData: (eventData: any) => set({ eventData }),
  userAccess: "",
  setUserAccess: (userAccess: string) => set({ userAccess })
}));
