import React, { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import QuestionItem from "./question-item";
import { toast } from "sonner";
import { Text } from "@/components/ui/text";
// import { Spinner } from "@/components/ui/spinner";

interface BpEventQuestionnaireProps {
  handleCancel: () => void;
  eventId?: string;
  handleJoinEvent: () => void;
  role?: string;
  questions?: any;
  setQuestions?: any;
  queResponses?: any;
  setQueresponses?: any;
  loading: boolean;
  open: boolean;
}

const QuestionState: React.FC<BpEventQuestionnaireProps> = ({
  handleCancel,
  eventId,
  handleJoinEvent,
  role,
  questions,
  queResponses,
  setQueresponses,
  setQuestions,
  loading,
  open
}) => {
  const { data: session } = useSession();

  //   if (fetchingQue) {
  //     return <Spinner />;
  //   }

  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Function to find and update the response by qId
  const updateResponse = (qId: any, updatedResponse: any) => {
    setQueresponses((prevResponses: any) => {
      const index = prevResponses.findIndex((r: any) => r.response.event_question_id === qId);
      if (index === -1) {
        // If the response does not exist, add a new one
        return [...prevResponses, { response: updatedResponse }];
      } else {
        // If the response exists, update it
        const newResponses = [...prevResponses];
        newResponses[index] = { response: updatedResponse };
        return newResponses;
      }
    });
  };

  const handleTextChange = (text: any, question: any) => {
    const updatedResponse = {
      event_question_id: question.question_id,
      event_question_version_id: question.question_version_id,
      response_text: [text],
      response_id: null
    };
    updateResponse(question.question_id, updatedResponse);
    validateQuestion(question, text);
  };

  const getPreviousQuestionText = (responses: any[]) => {
    if (responses.length > 0 && responses[0].response) {
      return {
        response_text: responses?.[0]?.response.response_text,
        response_id: responses?.[0]?.response.response_id
      };
    }
    return null;
  };

  const handleOptionSelection = (selected: any, question: any, checked?: boolean) => {
    let responseText = getPreviousQuestionText(queResponses)?.response_text || [];
    let responseId = getPreviousQuestionText(queResponses)?.response_id || [];

    if (question.type === "multi-select") {
      if (checked) {
        responseText = [...responseText, selected.option_text];
        responseId = [...responseId, selected.option_id];
      } else {
        responseText = responseText.filter((text: string) => text !== selected.option_text);
        responseId = responseId.filter((id: string) => id !== selected.option_id);
      }
    } else {
      responseText = [selected.option_text];
      responseId = [selected.option_id];
    }

    const updatedResponse = {
      event_question_id: question.question_id,
      event_question_version_id: question.question_version_id,
      response_text: responseText,
      response_id: responseId
    };

    updateResponse(question.question_id, updatedResponse);
    validateQuestion(question, selected);
  };

  const handleLongDescriptionChange = () => {};

  const validateQuestion = (question: any, value: string | string[]) => {
    if (question.is_required && (!value || value.length === 0)) {
      setErrors((prev) => ({ ...prev, [question.question_id]: "This field is required" }));
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[question.question_id];
        return newErrors;
      });
    }
  };

  const validateAllQuestions = (): boolean => {
    let hasErrors = false;
    const newErrors: { [key: string]: string } = {};

    questions.forEach((question: any) => {
      const response = queResponses.find(
        (r: any) => r.response.event_question_id === question.question_id
      );

      if (
        question.is_required &&
        (!response ||
          !response.response.response_text ||
          response.response.response_text.length === 0)
      ) {
        newErrors[question.question_id] = "This field is required";
        hasErrors = true;
      }
    });

    setErrors(newErrors);
    return !hasErrors;
  };

  const handleSubmit = () => {
    // Validate all questions
    if (validateAllQuestions()) {
      // No errors, proceed with submission
      handleJoinEvent();
    } else {
      // There are errors, don't submit
      toast.error("Fill all the required questions");
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleCancel}>
      <DialogContent className="md:w-auto w-full max-h-[800px] overflow-y-auto">
        <DialogDescription className="text-primary text-2xl font-extrabold ">
          Please fill your info
        </DialogDescription>
        <div>
          {Object.keys(errors).length !== 0 && (
            <Text className="text-danger-500" variant={"caption_one"}>
              Please fill all the required questions
            </Text>
          )}
          {questions.map((question: any, index: number) => (
            <QuestionItem
              index={index}
              key={question.question_id}
              question={question}
              queResponses={queResponses}
              hasError={hasError}
              handleTextChange={handleTextChange}
              handleOptionSelection={handleOptionSelection}
              handleLongDescriptionChange={handleLongDescriptionChange}
            />
          ))}
          <div className="flex justify-around gap-m">
            <Button
              onClick={() => {
                handleCancel();
                setQuestions([]);
                setQueresponses([]);
              }}
              variant="secondary"
              className="w-1/2"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              className="w-1/2"
              loading={loading}
              disabled={loading}
              variant="primary"
            >
              Submit
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default QuestionState;
